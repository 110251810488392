import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useLocation } from '@reach/router';

import { withSEO } from 'components/_hoc';
import ParentOnboarding from 'components/ParentOnboarding';
import { parseQueryString } from 'utils';

/**
 * Parent sign-up page
 */
const ParentSignupPage: FC = () => {
  const { search } = useLocation();
  const [data, setData] = useState<{
    team: 'Einstein Studios' | 'PRIDE';
    subject: 'spanish' | 'literacy';
    referrer?: string;
  }>();

  useEffect(() => {
    const referrer = parseQueryString('referrer', search);

    let subject = parseQueryString('subject', search);
    if (subject?.toLowerCase() === 'literacy') {
      subject = 'literacy';
    } else {
      subject = 'spanish';
    }

    let team: string | undefined;
    if (subject === 'spanish') {
      team = 'Einstein Studios';
    } else {
      team = parseQueryString('team', search);
      if (team?.toLowerCase() === 'einstein_studios') {
        team = 'Einstein Studios';
      } else {
        team = 'PRIDE';
      }
    }

    setData({
      team: team as 'Einstein Studios' | 'PRIDE',
      subject: subject as 'spanish' | 'literacy',
      referrer,
    });
  }, [setData, search]);

  return data === undefined ? null : <ParentOnboarding {...data} />;
};

export default withSEO(ParentSignupPage, {
  title: 'Parent Onboarding',
  description:
    'Apply for education in virtual reality. Tailored to the dyslexic strengths.',
  pathname: '/signup/parent',
});
