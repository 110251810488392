import React from 'react';
import type { Dispatch, FC, ReactElement, SetStateAction } from 'react';

import { Icon, IconButton, Stack } from '@chakra-ui/core';

import type { TrialAvailability } from 'types';

// PaginationButton component prop types
interface PaginationButtonsProps {
  slice: number;
  setSlice: Dispatch<SetStateAction<number>>;
  availabilities: TrialAvailability[];
}

/**
 * Left and right buttons to paginate displayed available classes
 */
const PaginationButtons: FC<PaginationButtonsProps> = ({
  slice,
  setSlice,
  availabilities,
}: PaginationButtonsProps) => {
  // Up slice of classes by 6
  const incrementSlice: VoidFunction = () => {
    setSlice(Math.min(availabilities.length, slice + 8));
  };

  // Down slice of classes by 6
  const decrementSlice: VoidFunction = () => {
    setSlice(Math.max(0, slice - 8));
  };

  return (
    <Stack
      isInline
      alignItems="center"
      justifyContent="center"
      mt={[4, null, 0]}
    >
      <IconButton
        variantColor="teal"
        variant="ghost"
        aria-label="Show further classes"
        icon={(): ReactElement => (
          <Icon name="chevron-left" fontSize="2.5rem" />
        )}
        onClick={decrementSlice}
        isDisabled={slice === 0}
      />
      <IconButton
        variantColor="teal"
        variant="ghost"
        aria-label="Show previous classes"
        icon={(): ReactElement => (
          <Icon name="chevron-right" fontSize="2.5rem" />
        )}
        onClick={incrementSlice}
        isDisabled={slice + 8 >= availabilities.length}
      />
    </Stack>
  );
};

export default PaginationButtons;
