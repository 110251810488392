import React from 'react';
import type { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Box, Flex, Stack, Text } from '@chakra-ui/core';
import Img from 'gatsby-image';

import {
  OnboardingSection,
  OnboardingSectionBody,
  OnboardingSectionTitle
} from 'components/_lib';
import { OnboardingSteps } from '../../types';
import type { StepsContainerProps } from '../../types';
import EditButton from './EditButton';

// ShippingInformation component prop types
type ShippingInformationProps = Pick<
  StepsContainerProps,
  'address' | 'profile' | 'setStep'
>;

/**
 *  Oculus VR shipping information
 */
const ShippingInformation: FC<ShippingInformationProps> = ({
  address,
  profile,
  setStep
}: ShippingInformationProps) => {
  const data = useStaticQuery(graphql`
    query {
      oculusImage: file(
        relativePath: { eq: "images/oculus-go-facing-right.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  // Backtrack to shipping form step
  const edit: VoidFunction = () => setStep(OnboardingSteps.shipping);

  return (
    <OnboardingSection>
      <OnboardingSectionTitle textAlign="left">
        Equipment ordered:
      </OnboardingSectionTitle>

      <OnboardingSectionBody>
        <Flex
          flexDirection={['column', null, 'row']}
          alignItems="center"
          justifyContent={['center', null, 'space-evenly']}
          w="100%"
        >
          <Stack isInline spacing={2} textAlign="left">
            <Box w="100px">
              <Img
                fluid={data.oculusImage.childImageSharp.fluid}
                alt="Oculus Go VR"
              />
            </Box>
            <Text>
              <strong>Oculus VR Headset</strong>
              <br />
              Compatible with our app
            </Text>
          </Stack>

          <Flex alignItems="center" justifyContent="center">
            <Text textAlign="left" mt={[8, null, 0]}>
              {profile.fullName}
              <br />
              {address.address1}
              <br />
              {address.address2 && (
                <>
                  {address.address2}
                  <br />
                </>
              )}
              {`${address.city}, ${address.state} ${address.zip}`}
              <br />
              {address.country}
              <br />
              {profile.phone}
              <br />
              {profile.email}
            </Text>
          </Flex>
        </Flex>

        <EditButton onClick={edit} />
      </OnboardingSectionBody>
    </OnboardingSection>
  );
};

export default ShippingInformation;
