import type { Dispatch, SetStateAction } from 'react';
import type { TrialAvailability } from 'types';

// Enumeration of onboarding steps
export enum OnboardingSteps {
  welcome,
  account,
  trialClass,
  shipping,
  summary,
  thankYou,
}

/**
 * New account information
 */

export interface ProfileState {
  [key: string]: string;
  student: string;
  fullName: string;
  preferredName: string;
  primaryEmail: string;
  phone: string;
}

/**
 * Scheduled free trial class
 */

export type TrialClassState = TrialAvailability | undefined;

/**
 * Mailing information
 */

export interface AddressState {
  [key: string]: string | undefined;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

/**
 * Content and action for MessageDialog
 */

export interface MessageDialogState {
  title: string;
  message: string;
  action: VoidFunction | null;
}

/**
 * StepsContainer component prop types
 */
export interface StepsContainerProps {
  step: OnboardingSteps;
  team: 'Einstein Studios' | 'PRIDE';
  subject: 'spanish' | 'literacy';
  setStep: Dispatch<SetStateAction<OnboardingSteps>>;
  handleNext: VoidFunction;
  profile: ProfileState;
  setProfile: Dispatch<SetStateAction<ProfileState>>;
  trialClass: TrialClassState;
  setTrialClass: Dispatch<SetStateAction<TrialClassState>>;
  needsOculus: boolean;
  setNeedsOculus: Dispatch<SetStateAction<boolean>>;
  address: AddressState;
  setAddress: Dispatch<SetStateAction<AddressState>>;
  handleSubmit: ({
    note,
    referredBy,
  }: {
    note?: string;
    referredBy?: string;
  }) => void;
  isSubmitting: boolean;
}

// Type of trial class time range option
export interface TimeRange {
  primaryText: string;
  secondaryText: string;
  start: number;
  end: number;
}
