import React from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';
import { format, parseISO } from 'date-fns';

import { SimpleGrid, Heading, Spinner, Stack, Text } from '@chakra-ui/core';

import { EinsteinRadioButton } from 'components/_lib';
import type { TrialAvailability } from 'types';

import type { StepsContainerProps } from '../../types';
import PaginationButtons from './PaginationButtons';

// ClassSelect component prop types
interface ClassSelectProps
  extends Pick<StepsContainerProps, 'trialClass' | 'setTrialClass'> {
  availabilities: TrialAvailability[];
  slice: number;
  setSlice: Dispatch<SetStateAction<number>>;
  loading: boolean;
}

/**
 * Radio buttons to select free trial class
 */
const ClassSelect: FC<ClassSelectProps> = ({
  trialClass,
  setTrialClass,
  slice,
  setSlice,
  availabilities,
  loading
}: ClassSelectProps) => {
  // Update selected free class
  const handleSelect = (availability: TrialAvailability): void => {
    setTrialClass({ ...availability });
  };

  return (
    <Stack
      flexDirection={['column', null, 'row']}
      justify={['center', null, 'space-around']}
      align="center"
      w="100%"
    >
      {loading ? (
        <Spinner size="lg" color="orange.500" />
      ) : availabilities.length === 0 ? (
        <Heading size="sm">None</Heading>
      ) : (
        <SimpleGrid
          columns={[2, null, 3, 4]}
          w={availabilities.length === 0 ? '615px' : 'auto'}
          maxW="100%"
        >
          {availabilities
            .slice(slice, Math.min(availabilities.length, slice + 8))
            .map(availability => {
              const datetime = parseISO(availability.datetime);
              const key = `${availability.id}-${availability.datetime}`;
              const current = `${trialClass?.id}-${trialClass?.datetime}`;

              return (
                <EinsteinRadioButton
                  key={key}
                  onClick={(): void => {
                    handleSelect(availability);
                  }}
                  isChecked={current === key}
                  m={[2, null, 4]}
                  d="block"
                >
                  <Text fontWeight="bold">
                    {format(datetime, 'eee, LLL dd')}
                  </Text>
                  <Text fontSize="sm" fontWeight="normal">
                    {format(datetime, 'p')}
                  </Text>
                </EinsteinRadioButton>
              );
            })}
        </SimpleGrid>
      )}

      {/* Paginate displayed class availabilities */}
      <PaginationButtons
        setSlice={setSlice}
        slice={slice}
        availabilities={availabilities}
      />
    </Stack>
  );
};

export default ClassSelect;
