import { getHours, parseISO } from 'date-fns';
import type { TrialAvailability } from 'types';
import TIME_RANGES from './timeRanges';

const cache: { [key: string]: TrialAvailability[] } = {};

/**
 * Filter availabilities within a given time range.
 *
 * @param allAvailabilities - All possible availabilities
 * @param range - The time range to filter by.
 */
const filterAvailabilities = (
  allAvailabilities: TrialAvailability[],
  range: string
): TrialAvailability[] => {
  if (cache[range]) return cache[range];

  const newAvailabilities = allAvailabilities.filter((availability) => {
    const hour = getHours(parseISO(availability.datetime));
    return hour >= TIME_RANGES[range].start && hour < TIME_RANGES[range].end;
  });

  cache[range] = newAvailabilities;
  return newAvailabilities;
};

export default filterAvailabilities;
