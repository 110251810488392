import React from 'react';
import type { FC } from 'react';
import { format, parseISO } from 'date-fns';

import { Avatar, Box, Flex, Icon, Stack, Text } from '@chakra-ui/core';

import {
  OnboardingSection,
  OnboardingSectionBody,
  OnboardingSectionTitle,
} from 'components/_lib';
import { OnboardingSteps } from '../../types';
import type { StepsContainerProps } from '../../types';
import EditButton from './EditButton';

// ScheduledClass component prop types
type ScheduledClassInformationProps = Pick<
  StepsContainerProps,
  'trialClass' | 'setStep' | 'subject' | 'team'
>;

/**
 * Information about scheduled free class
 */
const ScheduledClassInformation: FC<ScheduledClassInformationProps> = ({
  team,
  subject,
  trialClass,
  setStep,
}: ScheduledClassInformationProps) => {
  const date = parseISO(trialClass!.datetime);

  // Backtrack to free class scheduling step
  const edit: VoidFunction = () => setStep(OnboardingSteps.trialClass);

  return (
    <OnboardingSection>
      <OnboardingSectionTitle textAlign="left">
        Class chosen:
      </OnboardingSectionTitle>

      <OnboardingSectionBody>
        <Flex
          flexDirection={['column', null, 'row']}
          alignItems={['flex-start', null, 'center']}
          justifyContent="space-evenly"
          w={['max-content', null, '100%']}
        >
          <Box textAlign="left">
            <Text fontWeight="bold">
              {subject === 'spanish' ? 'Spanish Discovery' : 'OG Assessment'}
            </Text>
            <Text d="flex" alignItems="center">
              <Icon name="time" mr={1} /> {trialClass!.durationInMinutes} min.
              class
            </Text>
          </Box>
          <Text textAlign="left" my={[8, null, 0]}>
            <strong>{format(date, 'cccc, LLL dd')}</strong>
            <br />
            {format(date, 'p')}
          </Text>
          <Stack isInline align="center">
            <Avatar src={trialClass!.teacher.picture!} />
            <Box>
              <Text fontWeight="bold">{trialClass!.teacher.fullName}</Text>
              <Text>
                {subject === 'spanish'
                  ? 'Spanish teacher'
                  : `Certified ${team} tutor`}
              </Text>
            </Box>
          </Stack>
        </Flex>

        <EditButton onClick={edit} />
      </OnboardingSectionBody>
    </OnboardingSection>
  );
};

export default ScheduledClassInformation;
