import React from 'react';
import type { FC } from 'react';

import { OnboardingSteps } from '../types';
import type { StepsContainerProps } from '../types';
import ProfileStep from './ProfileStep';
import ShippingStep from './ShippingStep';
import SummaryStep from './SummaryStep';
import ThankYouStep from './ThankYouStep';
import TrialClassStep from './TrialClassStep';
import WelcomeStep from './WelcomeStep';

/**
 * Controls parent on-boarding steps
 */
const StepsContainer: FC<StepsContainerProps> = ({
  step,
  team,
  subject,
  setStep,
  handleNext,
  profile,
  setProfile,
  trialClass,
  setTrialClass,
  needsOculus,
  setNeedsOculus,
  address,
  setAddress,
  handleSubmit,
  isSubmitting,
}: StepsContainerProps) => {
  switch (step) {
    case OnboardingSteps.welcome:
      return <WelcomeStep handleNext={handleNext} subject={subject} />;

    case OnboardingSteps.account:
      return (
        <ProfileStep
          subject={subject}
          handleNext={handleNext}
          profile={profile}
          setProfile={setProfile}
          needsOculus={needsOculus}
          setNeedsOculus={setNeedsOculus}
        />
      );

    case OnboardingSteps.trialClass:
      return (
        <TrialClassStep
          team={team}
          subject={subject}
          handleNext={handleNext}
          trialClass={trialClass}
          setTrialClass={setTrialClass}
        />
      );

    case OnboardingSteps.shipping:
      return (
        <ShippingStep
          handleNext={handleNext}
          address={address}
          setAddress={setAddress}
          setNeedsOculus={setNeedsOculus}
        />
      );

    case OnboardingSteps.summary:
      return (
        <SummaryStep
          team={team}
          subject={subject}
          trialClass={trialClass}
          address={address}
          profile={profile}
          setStep={setStep}
          handleSubmit={handleSubmit}
          needsOculus={needsOculus}
          isSubmitting={isSubmitting}
        />
      );

    default:
      return <ThankYouStep subject={subject} needsOculus={needsOculus} />;
  }
};

export default StepsContainer;
