import React, { useEffect, useState, useRef } from 'react';
import type { FC } from 'react';
import ReactGA from 'react-ga';
import { format, parseISO } from 'date-fns';

import { Heading } from '@chakra-ui/core';

import API from 'api';
import {
  OnboardingSection,
  OnboardingSectionBody,
  OnboardingSectionTitle,
} from 'components/_lib';
import type { TrialAvailability } from 'types';

import NextButton from '../NextButton';
import type { StepsContainerProps } from '../../types';
import ClassSelect from './ClassSelect';
import filterAvailabilities from './filterAvailabilities';
import TimeRangeSelect from './TimeRangeSelect';

// TrialClassStepContainer component prop types
type TrialClassStepContainerProps = Pick<
  StepsContainerProps,
  'handleNext' | 'trialClass' | 'setTrialClass' | 'subject' | 'team'
>;

/**
 * Page to schedule free first class, step 2
 */
const TrialClassStepContainer: FC<TrialClassStepContainerProps> = ({
  team,
  subject,
  handleNext,
  trialClass,
  setTrialClass,
}: TrialClassStepContainerProps) => {
  // Selected time change
  const [timeRange, setTimeRange] = useState<'morning' | 'afternoon' | 'night'>(
    'morning'
  );

  // Classes slice range
  const [slice, setSlice] = useState<number>(0);

  // Displayed trial class availabilities
  const [availabilities, setAvailabilities] = useState<TrialAvailability[]>([]);

  // All possible availabilities fetched from API
  const allAvailabilities = useRef<TrialAvailability[]>();

  // Proceed to next step
  const handleClick: VoidFunction = () => {
    handleNext();
    ReactGA.event({
      category: 'Onboarding',
      action: 'Selected Free Class',
      label: subject,
    });
  };

  useEffect((): void => {
    const fetchAvailabilities = async (): Promise<void> => {
      try {
        const response = await API.get('/availabilities/trial', {
          params: { subject, team },
        });
        allAvailabilities.current = response.data;
      } catch {
        allAvailabilities.current = [];
      } finally {
        setAvailabilities(
          filterAvailabilities(allAvailabilities.current!, timeRange)
        );
      }
    };

    if (allAvailabilities.current === undefined) {
      fetchAvailabilities();
    } else {
      setAvailabilities(
        filterAvailabilities(allAvailabilities.current!, timeRange)
      );
      setSlice(0);
    }
  }, [subject, team, timeRange]);

  return (
    <OnboardingSection textAlign="center">
      <OnboardingSectionTitle w={['85%', '100%']} textAlign="center">
        Next, schedule your free assessment & discovery class (50 minutes).
      </OnboardingSectionTitle>
      <OnboardingSectionBody>
        {/* Select time range */}
        <OnboardingSection>
          <OnboardingSectionTitle
            fontSize="md"
            fontWeight="normal"
            textAlign={['center', null, 'left']}
          >
            Select the time range that best fits your schedule:
          </OnboardingSectionTitle>
          <OnboardingSectionBody>
            <TimeRangeSelect
              timeRange={timeRange}
              setTimeRange={setTimeRange}
            />
          </OnboardingSectionBody>
        </OnboardingSection>

        {/* Select class */}
        <OnboardingSection>
          <OnboardingSectionTitle
            fontSize="md"
            fontWeight="normal"
            textAlign={['center', null, 'left']}
          >
            Our next available assessment class is:
            <br />
            <strong>
              {availabilities.length > 0 &&
                `${format(
                  parseISO(availabilities[0].datetime),
                  "cccc, LLL dd 'at' p"
                )}`}
            </strong>
          </OnboardingSectionTitle>
          <OnboardingSectionBody>
            <ClassSelect
              trialClass={trialClass}
              setTrialClass={setTrialClass}
              slice={slice}
              setSlice={setSlice}
              availabilities={availabilities}
              loading={allAvailabilities.current === undefined}
            />
          </OnboardingSectionBody>
        </OnboardingSection>

        {trialClass && (
          <Heading size="sm" fontWeight={400}>
            Selected class: <br />
            <strong>
              {format(parseISO(trialClass.datetime), "cccc, LLL dd 'at' p")}
            </strong>
          </Heading>
        )}

        <NextButton onClick={handleClick} isDisabled={!trialClass}>
          Book this class
        </NextButton>
      </OnboardingSectionBody>
    </OnboardingSection>
  );
};

export default TrialClassStepContainer;
