import React from 'react';
import type { FC } from 'react';

import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/core';

import ValidationCircleSvg from 'assets/svgs/validation-circle.svg';
import {
  ButtonChakraLink,
  Emoji,
  OnboardingSection,
  OnboardingSectionBody,
} from 'components/_lib';
import type { StepsContainerProps } from '../types';

type ThankYouStepProps = Pick<StepsContainerProps, 'subject' | 'needsOculus'>;

/**
 * Thank you and next steps note, end of on-boarding
 */
const ThankYouStep: FC<ThankYouStepProps> = ({
  subject,
  needsOculus,
}: ThankYouStepProps) => (
  <OnboardingSection>
    <OnboardingSectionBody textAlign="center">
      <Flex w="6rem" h="6rem">
        <ValidationCircleSvg />
      </Flex>
      <Heading size="md">We have received your application!</Heading>
      {subject === 'spanish' || !needsOculus ? (
        <Text maxW={['100%', '375px']}>
          Wow, thank you! We are{' '}
          <Box as="span" d="inline" color="orange.500">
            reviewing your account
          </Box>
          , and promise to get back to you ASAP.
        </Text>
      ) : (
        <Stack spacing={8} align="center">
          <Text maxW={['100%', '375px']}>
            Wow, thank you! We are{' '}
            <Box as="span" d="inline" color="orange.500">
              reviewing your account
            </Box>
            , and promise to get back to you ASAP.
          </Text>
          <Text maxW={['100%', '375px']}>
            Look out for an{' '}
            <Box as="span" d="inline" color="orange.500">
              invoice for a deposit toward your own VR headset
            </Box>
            . Once paid, we&apos;ll lend you the headset so that you can get set
            up for your first class!{' '}
            <Emoji symbol="😁" label="grinning-face-with-smiling-eyes" />
          </Text>
        </Stack>
      )}

      <ButtonChakraLink
        aria-label="return to main website"
        size="lg"
        variant="outline"
        href={process.env.LANDING_PAGE_URL}
      >
        Return to website
      </ButtonChakraLink>
    </OnboardingSectionBody>
  </OnboardingSection>
);

export default ThankYouStep;
