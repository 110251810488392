import React from 'react';
import type {
  ChangeEventHandler,
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
} from 'react';

import styled from '@emotion/styled';
import { Checkbox, Flex, Text } from '@chakra-ui/core';

import theme from 'theme';
import TIME_RANGES from './timeRanges';

// Custom styled Chakra-UI checkbox
const StyledCheckbox = styled(Checkbox)`
  input[type='checkbox'] + div {
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.teal['500']};
    border-radius: 4px;
    height: 18px;
    width: 18px;
  }

  input[type='checkbox']:checked + div {
    background: ${theme.colors.orange['500']};
    background-color: ${theme.colors.orange['500']};
    border-color: ${theme.colors.orange['500']};
  }

  input[type='checkbox'] + div + div {
    margin-left: 1rem;
    text-align: left;
  }
`;

// TimeRangeSelect component prop types
interface TimeRangeSelectProps {
  timeRange: string;
  setTimeRange: Dispatch<SetStateAction<'morning' | 'afternoon' | 'night'>>;
}

/**
 * Radio buttons to select free class time range - morning/afternoon/night
 */
const TimeRangeSelect: FC<TimeRangeSelectProps> = ({
  timeRange,
  setTimeRange,
}: TimeRangeSelectProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setTimeRange(target.value as 'morning' | 'afternoon' | 'night');
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection={['column', null, 'row']}
      w="100%"
    >
      {Object.keys(TIME_RANGES).map(
        (key): ReactElement => (
          <StyledCheckbox
            key={key}
            value={key}
            isChecked={timeRange === key}
            onChange={handleChange}
            mx={[0, null, 8, 16]}
            my={[4, 8, 0]}
          >
            <strong>{TIME_RANGES[key].primaryText}</strong>
            <br />
            <Text fontSize="sm">{TIME_RANGES[key].secondaryText}</Text>
          </StyledCheckbox>
        )
      )}
    </Flex>
  );
};

export default TimeRangeSelect;
