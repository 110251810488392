import React from 'react';
import type { FC } from 'react';

import { Box } from '@chakra-ui/core';
import styled from '@emotion/styled';

import colors from 'theme/colors';
import { OnboardingSteps } from './types';

// Stepper wrapper
const StepList = styled.ul`
  overflow: auto;
`;

// Stepper item
const Step = styled.li`
  color: ${colors.teal['500']};
  list-style-type: none;
  float: left;
  width: 33.333%;
  position: relative;
  text-align: center;

  &:before {
    background-color: ${colors.teal['500']};
    border: 1px solid ${colors.teal['500']};
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    margin: 0 auto 10px auto;
    width: 20px;
  }

  &:after {
    background-color: ${colors.teal['500']};
    content: '';
    height: 2px;
    position: absolute;
    width: 100%;
    top: 10px;
    left: -50%;
    z-index: -1;
  }

  &:first-of-type:after {
    content: none;
  }

  &.active {
    color: ${colors.orange['500']};
  }

  &.active:before {
    border-color: ${colors.orange['500']};
    background-color: ${colors.orange['500']};
  }

  &.active:after {
    background-color: ${colors.orange['500']};
  }

  @media (max-width: 48em) {
    font-size: 0;
  }
`;

// ProgressStepper component prop types
interface ProgressStepperProps {
  step: number;
}

/**
 * Onboarding progress stepper
 */
const ProgressStepper: FC<ProgressStepperProps> = ({
  step,
}: ProgressStepperProps) => (
  <Box w="100%" my={8}>
    <StepList>
      <Step className={step >= OnboardingSteps.account ? 'active' : undefined}>
        Account
      </Step>
      <Step
        className={step >= OnboardingSteps.trialClass ? 'active' : undefined}
      >
        Free Assessment
      </Step>
      <Step className={step >= OnboardingSteps.summary ? 'active' : undefined}>
        Wrap Up
      </Step>
    </StepList>
  </Box>
);

export default ProgressStepper;
