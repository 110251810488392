import React from 'react';
import type { FC } from 'react';

import { Button, Flex } from '@chakra-ui/core';
import type { ButtonProps } from '@chakra-ui/core';

// EditButton component prop types
type EditButtonProps = Omit<ButtonProps, 'children'>;

/**
 * Button to backtrack to previous on-boarding steps
 */
const EditButton: FC<EditButtonProps> = (props: EditButtonProps) => (
  <Flex w="100%" justify="flex-end">
    <Button
      rightIcon="chevron-right"
      variantColor="teal"
      variant="ghost"
      {...props}
    >
      Edit
    </Button>
  </Flex>
);

export default EditButton;
