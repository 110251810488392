import React from 'react';
import type { FC } from 'react';
import ReactGA from 'react-ga';
import { graphql, useStaticQuery } from 'gatsby';

import { Box, Link, Text } from '@chakra-ui/core';
import Img from 'gatsby-image';

import { OnboardingSection, OnboardingSectionBody } from 'components/_lib';
import type { StepsContainerProps } from '../types';
import NextButton from './NextButton';

const SpanishWelcome = (
  <Box w={['275px', '100%']} maxW="100%">
    ¡Holá! Welcome to Einstein Studios!
    <br />
    <br />
    To get started with our{' '}
    <Text d="inline-flex" color="orange.500">
      Spanish
    </Text>{' '}
    classes, let&apos;s{' '}
    <Text d="inline-flex" color="orange.500">
      create your account
    </Text>
    , book a{' '}
    <Text d="inline-flex" color="orange.500">
      free initial assessment
    </Text>
    , and prepare shipping for a{' '}
    <Text d="inline-flex" color="orange.500">
      VR headset
    </Text>
    .
  </Box>
);

const PrideWelcome = (
  <Box w="100%">
    Hello! Welcome to PRIDE Reading Program - Einstein Studios!
    <br />
    <br />
    To get started with our{' '}
    <Text d="inline-flex" color="orange.500">
      structured literacy
    </Text>{' '}
    classes, let&apos;s{' '}
    <Text d="inline-flex" color="orange.500">
      create your account
    </Text>
    , book an{' '}
    <Text d="inline-flex" color="orange.500">
      initial assessment
    </Text>{' '}
    with one of PRIDE&apos;s{' '}
    <Text d="inline-flex" color="orange.500">
      Orton-Gillingham tutors
    </Text>
    , and prepare shipping for a{' '}
    <Text d="inline-flex" color="orange.500">
      VR headset
    </Text>
    .
  </Box>
);

// WelcomeStep component prop types
type WelcomeStepProps = Pick<StepsContainerProps, 'handleNext' | 'subject'>;

/**
 * On-boarding welcome and get started, step 0
 */
const WelcomeStep: FC<WelcomeStepProps> = ({
  handleNext,
  subject,
}: WelcomeStepProps) => {
  const data = useStaticQuery(graphql`
    query {
      prideLogoFlat: file(relativePath: { eq: "images/pride-logo-flat.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      deliveryTruck: file(relativePath: { eq: "images/delivery-truck.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  // Proceed to next step
  const handleClick: VoidFunction = () => {
    handleNext();
    ReactGA.event({
      category: 'Onboarding',
      action: 'Got Started',
      label: subject,
    });
  };

  return (
    <OnboardingSection>
      <OnboardingSectionBody w="500px" maxW="100%" textAlign="center">
        {subject === 'spanish' ? SpanishWelcome : PrideWelcome}
        <Box w="100%">
          <Img
            fluid={
              subject === 'spanish'
                ? data.deliveryTruck.childImageSharp.fluid
                : data.prideLogoFlat.childImageSharp.fluid
            }
            alt={
              subject === 'spanish'
                ? 'Delivery truck with packages falling out back'
                : 'PRIDE Reading Program'
            }
          />
        </Box>
        <NextButton onClick={handleClick} w="100%">
          Get Started
        </NextButton>
        <Text maxW="400px">
          By clicking &quot;Get Started&quot;, you agree to the{' '}
          <Link color="orange.500" href="/terms-of-service.pdf" isExternal>
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link color="orange.500" href="/privacy-policy.pdf" isExternal>
            Privacy Policy
          </Link>
          .
        </Text>
      </OnboardingSectionBody>
    </OnboardingSection>
  );
};

export default WelcomeStep;
