import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import ReactGA from 'react-ga';

import { useDisclosure } from '@chakra-ui/core';

import API from 'api';
import { Section, SectionTitle } from 'components/_lib';
import MessageModal from './MessageDialog';
import ProgressStepper from './ProgressStepper';
import StepButton from './StepButton';
import ParentOnboardingSteps from './Steps';
import { OnboardingSteps } from './types';
import type {
  AddressState,
  MessageDialogState,
  ProfileState,
  TrialClassState,
} from './types';

// Onboarding step titles
const TITLES = [
  'Welcome!',
  'Your information',
  'Schedule a 50-minute discovery class',
  'VR headset shipping',
  'Summary',
  'Thank you!',
];

// ParentOnboardingContainer component prop types
interface ParentOnboardingContainerProps {
  subject: 'spanish' | 'literacy';
  team: 'Einstein Studios' | 'PRIDE';
  referrer?: string;
}

/**
 * Wraps parent on-boarding process
 */
const ParentOnboardingContainer: FC<ParentOnboardingContainerProps> = ({
  subject,
  team,
  referrer,
}: ParentOnboardingContainerProps) => {
  // Current on-boarding step
  const [step, setStep] = useState<OnboardingSteps>(OnboardingSteps.welcome);

  // New account information state
  const [profile, setProfile] = useState<ProfileState>({
    student: '',
    fullName: '',
    preferredName: '',
    primaryEmail: '',
    phone: '',
  });

  // Trial class
  const [trialClass, setTrialClass] = useState<TrialClassState>();

  // Oculus VR already owned boolean
  const [needsOculus, setNeedsOculus] = useState<boolean>(true);

  // Mailing information state
  const [address, setAddress] = useState<AddressState>({
    address1: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  });

  // Content and action for MessageModal
  const [messageInfo, setMessageInfo] = useState<MessageDialogState>({
    title: '',
    message: '',
    action: null,
  });

  // Submission in progress boolean
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // MessageModal state
  const { isOpen, onClose, onOpen } = useDisclosure();

  /**
   * Back up to the previous step in the onboarding process
   */
  const handleBack: VoidFunction = () => {
    switch (step) {
      case OnboardingSteps.welcome:
        window.location.href = process.env.LANDING_PAGE_URL!;
        break;

      case OnboardingSteps.account:
        setStep(OnboardingSteps.welcome);
        break;

      case OnboardingSteps.trialClass:
        setStep(OnboardingSteps.account);
        break;

      case OnboardingSteps.shipping:
        setStep(OnboardingSteps.trialClass);
        break;

      case OnboardingSteps.summary:
        setStep(
          !needsOculus ? OnboardingSteps.trialClass : OnboardingSteps.shipping
        );
        break;

      default:
        break;
    }
  };

  /**
   * Advance to the next step in the onboarding process
   */
  const handleNext: VoidFunction = () => {
    switch (step) {
      case OnboardingSteps.welcome:
        setStep(OnboardingSteps.account);
        break;

      case OnboardingSteps.account:
        setStep(OnboardingSteps.trialClass);
        break;

      case OnboardingSteps.trialClass:
        setStep(
          needsOculus ? OnboardingSteps.shipping : OnboardingSteps.summary
        );
        break;

      case OnboardingSteps.shipping:
        setStep(OnboardingSteps.summary);
        break;

      case OnboardingSteps.summary:
        setStep(OnboardingSteps.thankYou);
        break;

      case OnboardingSteps.thankYou:
        window.location.href = process.env.LANDING_PAGE_URL!;
        break;

      default:
        break;
    }
  };

  /**
   * Submit on-boarding registration to API
   *
   * @param note - Optional parent note to submit w/ application
   */
  const handleSubmit = async ({
    note,
    referredBy,
  }: {
    note?: string;
    referredBy?: string;
  }): Promise<void> => {
    setIsSubmitting(true);
    const { student, ...parent } = profile;

    try {
      await API.post(`/waitlist/${subject}`, {
        ...parent,
        address: needsOculus ? address : undefined,
        student,
        availabilityId: trialClass!.id,
        datetime: trialClass!.datetime,
        teams: {
          spanish: subject === 'spanish' ? team : undefined,
          literacy: subject === 'literacy' ? team : undefined,
        },
        needsOculus,
        note,
        referrer,
        referredBy,
      });

      setIsSubmitting(false);
      handleNext();
      return ReactGA.event({
        category: 'Onboarding',
        action: 'Completed Entire Process',
        label: subject,
      });
    } catch (error) {
      const isReferralError =
        error.response.data.message === 'Invalid referrer';
      const isDuplicateParentError =
        error.response.data.message === 'Duplicate parent';
      const isDuplicateClassError =
        error.response.data.message === 'Class has already been booked';

      setMessageInfo({
        title: error.response.status === 409 ? 'Conflict' : 'An Error Occurred',
        message: isReferralError
          ? 'The referral link you are using is invalid. To sign-up with a referral, please double-check your link and refresh the page.'
          : isDuplicateParentError
          ? 'The email you entered already exists with an account. Please log in to your existing account, or enter a new email address.'
          : isDuplicateClassError
          ? 'The assessment class you selected has just been booked. Please select a new class.'
          : 'Apologies, we could not process your application at this time. Please reach out to us or try again later.',
        action: isDuplicateParentError
          ? (): void => {
              setProfile({ ...profile, primaryEmail: '' });
              setStep(OnboardingSteps.account);
            }
          : isDuplicateClassError
          ? (): void => {
              setTrialClass(undefined);
              setStep(OnboardingSteps.trialClass);
            }
          : null,
      });
      onOpen();
      return setIsSubmitting(false);
    }
  };

  useEffect((): void => {
    if (referrer) {
      (async (): Promise<void> => {
        try {
          const { data: isValid } = await API.get(
            '/waitlist/validate/referrer',
            { params: { referrer } }
          );
          setMessageInfo({
            title: isValid ? 'Referral Validated!' : 'Inactive Referral',
            message: isValid
              ? 'By signing up with this link, you will receive 2 free classes upon purchasing any of our subscriptions. The person who referred you will receive their own free classes as well. 🙂'
              : 'Unfortunately, the referral link you are using is inactive 🙁. To sign-up with a referral, please double-check your link and refresh the page.',
            action: null,
          });
        } catch {
          setMessageInfo({
            title: 'Invalid Referral',
            message:
              'The referral link you are using is invalid 😕. To sign-up with a referral, please double-check your link and refresh the page.',
            action: null,
          });
        } finally {
          onOpen();
        }
      })();
    }
  }, [referrer, onOpen]);

  return (
    <>
      <Section
        d="flex"
        px={[4, 6, 8, 10]}
        py={[3, 4, 5, 6]}
        bg="rgba(216, 216, 216, 0.2)"
        rounded="lg"
        border="1px solid rgb(151, 151, 151, 0.2)"
        boxShadow="lg"
      >
        <StepButton
          step={step}
          onClick={step !== OnboardingSteps.thankYou ? handleBack : handleNext}
        />
        <SectionTitle whiteSpace="nowrap" overflow="auto">
          {TITLES[step]}
        </SectionTitle>
        <ProgressStepper step={step} />
        <ParentOnboardingSteps
          step={step}
          team={team}
          subject={subject}
          setStep={setStep}
          handleNext={handleNext}
          profile={profile}
          setProfile={setProfile}
          trialClass={trialClass}
          setTrialClass={setTrialClass}
          needsOculus={needsOculus}
          setNeedsOculus={setNeedsOculus}
          address={address}
          setAddress={setAddress}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </Section>
      <MessageModal isOpen={isOpen} onClose={onClose} {...messageInfo} />
    </>
  );
};

export default ParentOnboardingContainer;
