import React, { useState } from 'react';
import type { FC } from 'react';

import { Box } from '@chakra-ui/core';

import { OnboardingSection, OnboardingSectionBody } from 'components/_lib';
import type { StepsContainerProps } from '../../types';
import NextButton from '../NextButton';
import NotesForm from './NotesForm';
import ScheduledClassInformation from './ScheduledClassInformation';
import ShippingInformation from './ShippingInformation';

// SummaryStepContainer component prop types
type SummaryStepContainerProps = Pick<
  StepsContainerProps,
  | 'trialClass'
  | 'address'
  | 'profile'
  | 'setStep'
  | 'handleSubmit'
  | 'needsOculus'
  | 'isSubmitting'
  | 'subject'
  | 'team'
>;

/**
 * Wraps on-boarding summary, step 4
 */
const SummaryStepContainer: FC<SummaryStepContainerProps> = ({
  trialClass,
  address,
  profile,
  setStep,
  handleSubmit,
  needsOculus,
  isSubmitting,
  subject,
  team,
}: SummaryStepContainerProps) => {
  // Optional note form input
  const [note, setNote] = useState<string>('');

  // Optional referred by input
  const [referredBy, setReferredBy] = useState<string>('');

  // Proceed to payment step
  const handleClick: VoidFunction = () => {
    handleSubmit({
      note: note.length === 0 ? undefined : note,
      referredBy: referredBy.length === 0 ? undefined : referredBy,
    });
  };

  const divider = (
    <Box w="100%" borderBottom="1px solid" borderColor="gray.500" />
  );

  return (
    <OnboardingSection>
      <OnboardingSectionBody>
        <ScheduledClassInformation
          team={team}
          subject={subject}
          trialClass={trialClass}
          setStep={setStep}
        />
        {needsOculus && divider}
        {needsOculus && (
          <ShippingInformation
            address={address}
            setStep={setStep}
            profile={profile}
          />
        )}
        {divider}
        <Box w="100%">
          <NotesForm
            profile={profile}
            note={note}
            handleNoteChange={({ target }) => {
              setNote(target.value);
            }}
            referredBy={referredBy}
            handleReferredByChange={({ target }) => {
              setReferredBy(target.value);
            }}
          />
        </Box>
        <NextButton
          onClick={handleClick}
          isLoading={isSubmitting}
          loadingText="Submitting"
        >
          Submit
        </NextButton>
      </OnboardingSectionBody>
    </OnboardingSection>
  );
};

export default SummaryStepContainer;
