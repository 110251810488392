import React from 'react';
import type { FC } from 'react';
import ReactGA from 'react-ga';

import {
  OnboardingSection,
  OnboardingSectionBody,
  OnboardingSectionTitle
} from 'components/_lib';
import type { StepsContainerProps } from '../../types';
import AccountForm from './ProfileForm';

// ProfileStepContainer component prop types
type ProfileStepContainerProps = Pick<
  StepsContainerProps,
  | 'subject'
  | 'handleNext'
  | 'profile'
  | 'setProfile'
  | 'needsOculus'
  | 'setNeedsOculus'
>;

/**
 * Wraps account information form, step 1
 */
const ProfileStepContainer: FC<ProfileStepContainerProps> = ({
  handleNext,
  profile,
  subject,
  setProfile,
  needsOculus,
  setNeedsOculus
}: ProfileStepContainerProps) => {
  // Error check form values before proceeding
  const handleSubmit = async (): Promise<void> => {
    handleNext();
    return ReactGA.event({
      category: 'Onboarding',
      action: 'Entered New Account Information',
      label: !needsOculus
        ? 'With Oculus Go VR checked as already owned'
        : 'With Oculus Go VR checked as not owned'
    });
  };

  return (
    <OnboardingSection textAlign="center">
      <OnboardingSectionTitle w={['85%', '100%']} textAlign="center">
        First, let&apos;s get you set up with an account.
      </OnboardingSectionTitle>
      <OnboardingSectionBody>
        <AccountForm
          profile={profile}
          setProfile={setProfile}
          handleSubmit={handleSubmit}
          needsOculus={needsOculus}
          setNeedsOculus={setNeedsOculus}
          subject={subject}
        />
      </OnboardingSectionBody>
    </OnboardingSection>
  );
};

export default ProfileStepContainer;
