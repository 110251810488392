import { TimeRange } from '../../types';

// Possible trial class time ranges
const TIME_RANGES: { [key: string]: TimeRange } = {
  morning: {
    primaryText: 'Morning',
    secondaryText: '8AM - 1PM',
    start: 8,
    end: 13
  },
  afternoon: {
    primaryText: 'Afternoon',
    secondaryText: '1PM - 6PM',
    start: 13,
    end: 18
  },
  night: {
    primaryText: 'Night',
    secondaryText: '6PM - 11PM',
    start: 18,
    end: 23
  }
};

export default TIME_RANGES;
