import React, { memo } from 'react';
import type { ChangeEventHandler, FC } from 'react';

import styled from '@emotion/styled';
import { Box, Radio, Stack, Text } from '@chakra-ui/core';

import theme from 'theme';
import type { StepsContainerProps } from '../../types';

// Custom styled Chakra-UI Radio
const StyledRadio = styled(Radio)`
  div[type='radio'] {
    border: 1px solid ${theme.colors.gray['500']};
    background: ${theme.colors.white};
  }
`;

// NeedsOculusRadio component prop types
type NeedsOculusRadioProps = Pick<
  StepsContainerProps,
  'needsOculus' | 'setNeedsOculus' | 'subject'
>;

/**
 * Radio buttons to select yes or no indicating Oculus is already owned
 */
const NeedsOculusRadio: FC<NeedsOculusRadioProps> = ({
  subject,
  needsOculus,
  setNeedsOculus
}: NeedsOculusRadioProps) => {
  // Set needsOculus to false
  const checkYes: ChangeEventHandler = () => setNeedsOculus(false);

  // Set needsOculus to true
  const checkNo: ChangeEventHandler = () => setNeedsOculus(true);

  return (
    <Stack spacing={4} align="center">
      <Box>
        <Text color="orange.500">
          I own an {subject === 'spanish' ? 'Oculus Go' : 'Oculus Go or Quest'}{' '}
          VR headset:
        </Text>
        <Text color="teal.500">
          [No worries if not, we&apos;ll lend you one!]
        </Text>
      </Box>
      <Stack spacing={16} isInline>
        <StyledRadio
          fontSize="sm"
          variantColor="orange"
          onChange={checkYes}
          isChecked={!needsOculus}
        >
          Yes
        </StyledRadio>
        <StyledRadio
          fontSize="sm"
          variantColor="orange"
          onChange={checkNo}
          isChecked={needsOculus}
        >
          No
        </StyledRadio>
      </Stack>
    </Stack>
  );
};

export default memo(NeedsOculusRadio);
